* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    background-image: url('/public/images/img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .services ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .services li {
    flex-basis: 25%;
    margin: 20px;
  }
  
  .service-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100%;
  }
  
  .service-box:hover {
    background-color: #f7f7f7;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .service-box i {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .service-box span {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .service-info {
    display: none;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .service-box:hover .service-info {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .products {
    background-image: url('/public/images/img-1.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .authentication-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .buttons button {
    background-color: #4CAF50;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .buttons button:hover {
    background-color: #3e8e41;
  }
  .authentication-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .sign-up-container, .sign-in-container {
    display: flex;
    justify-content: center;
  }
  
  .sign-up-form, .sign-in-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .sign-up-form label, .sign-in-form label {
    margin-bottom: 10px;
  }
  
  .sign-up-form input, .sign-in-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .sign-up-form button, .sign-in-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #4CAF50;
    color: #fff;
    cursor: pointer;
  }
  
  .sign-up-form button:hover, .sign-in-form button:hover {
    background-color: #3e8e41;
  }
  .photography {
    background-image: url('/public/images/img-5.jpg');
    background-image: url();
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .photography-gallery {
    padding: 20px;
  }